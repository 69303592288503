html, body, #app, #app>div {
    height: 100%;
    font: normal 12px "Verdana", sans-serif;
    color: #000;
  }
#root {
    height: 100%; 
}
.app {
    height:100%;
}
.campaign-pdf, .frame-pdf {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
}
