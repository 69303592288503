.logo {
    background-repeat: no-repeat;
    background-position: center;
    background-color: #F7F7F7;
    background-size: contain;
    height: 120px;
}

.AR {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/alfa_romeo_logo.png');
    background-color: #000;
}

.AU {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/audi_logo.png');
}

.BM {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/bmw_logo.png');
}

.AC {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/acura_logo.png');
}

.BU {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/buick_logo.png');
}

.CA {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/cadillac_logo.png');
}

.CH {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/chevrolet_logo.png');
}

.CR {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/chrysler_logo.png');
}

.DO {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/dodge_logo.png');
}

.FD {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/ford_logo.png');
}

.GE {
    background-color: #000;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/genesis_logo.png');
}

.GM {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/gmc_logo.png');
}

.HD {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/honda_logo.png');
}

.HY {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/hyundai_logo.png');
}

.IN {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/infiniti_logo.png');
}

.JA {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/jaguar_logo.png?a');
}

.JE {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/jeep_logo.png');
}

.KI {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/kia_logo.png');
}

.LE {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/lexus_logo.png');
    background-color: #FFF;
}

.LI {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/lincoln_logo.png');
    background-color: #FFF;
}

.LR {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/landrover_logo.png');
}

.MA {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/maserati_logo.png');
    background-color: #000;
}

.MB {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/mb_logo.png');
    background-color: #000;
}

.MI {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/mini_logo.png');
}

.MZ {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/mz_logo.png');
    background-color: #101010;
}

.NI {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/nissan_logo.png');
}

.PR {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/porsche_logo.png');
}

.RA {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/ram_logo.png');
}

.SU {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/subaru_logo.png');
}

.TO {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/toyota_logo.png');
}

.VO {
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/volvo_logo.png');
}

.VW {
    background-color: #FFF;
    background-image: url('https://static.findmyupgrade.com/media-am/unsubscribe_logos/volkswagen_logo.png');
}