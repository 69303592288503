.Unsubscribe-warning-text {
    color: red;
}

/* small */
@media screen and (min-width: 750px) {
    .unsubscribe-page {
        max-width: 75%;
    }
}

/* medium viewport */
@media screen and (min-width: 992px) {
    .unsubscribe-page {
        max-width: 75%;
    }
}

/* large viewport */
@media screen and (min-width: 1200px) {
    .unsubscribe-page {
        max-width: 50%;
    }
}

/* x-large viewport */
@media screen and (min-width: 1400px) {
    .unsubscribe-page {
        max-width: 50%;
    }
}