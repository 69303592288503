.landing-page {
    font-size: 12px;
}

.landing-page .content h2 { 
    font-size: 16px;
    font-weight: bold;
    padding-top: 20px;
    margin: 13px 0;
}

.landing-page .content > p { 
    padding-top: 20px;
}

/* small */
@media screen and (min-width: 750px) {
    .landing-page {
        max-width: 75%;
    }
}

/* medium viewport */
@media screen and (min-width: 992px) {
    .landing-page {
        max-width: 75%;
    }
}

/* large viewport */
@media screen and (min-width: 1200px) {
    .landing-page {
        max-width: 50%;
    }
}

/* x-large viewport */
@media screen and (min-width: 1400px) {
    .landing-page {
        max-width: 50%;
    }
}