.hright {
    background-color: #333333;
    color: white;
    float: left;
    height: 120px;
}

.AU {
    background-color: #C1002A;
}

.BM {
    background-color: #DEE0E0;
    color: #333333;
}

.CA {
    background-color: #4B4B4B;
}

.VW {
    background-color: #959aa0;
}

.PR {
    background-color: #000000;
}

.BU {
    background-color: #55565A;
}

.HY {
    background-color: #000;
}

.hright p {
    margin: 10px;
    padding: 0;
    font-weight: bold;
    color: #7E7E80;
    font-size: 14px;
}

.text-right {
    text-align: right;
}

.dealer-info {
    padding: 1px;
}

.dealer-info-bold {
    font-size: 14px;
    font-weight: bold;
}